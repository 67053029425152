import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import data from "../data";
import { useTranslation } from "react-i18next";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";

function Navbar() {
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(true);

  const [isDropdownShown, setIsDropdownShown] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState("");

  const { t, i18n } = useTranslation();

  const handleChooseLan = (language) => {
    setSelectedLanguage(language);

    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  };

  const languages = [
    ["🇬🇧 English", "en"],
    ["🇮🇩 Bahasa Indonesia", "id"],
    // ["🇨🇳 官话", "zn"],
  ];

  const handleClick = () => setClick(!click);
  const toggleMobileDropdown = () => setIsDropdownShown(!isDropdownShown);

  const dropdownClass = classNames("dropdown", {
    "hide-dropdown": !isDropdownShown,
    "show-dropdown": isDropdownShown,
  });

  const changeBackground = () => {
    if (window.scrollY > 680) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  };

  useEffect(() => {
    changeBackground();
    const currLan = localStorage.getItem("language");
    setSelectedLanguage(currLan);
  }, []);

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <nav className={navbar ? "navbar" : "navbar active"}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img
              className="navbar-logo"
              src={
                navbar
                  ? "images/anindya-logo-white.png"
                  : "images/anindya-logo.png"
              }
              alt="Anindya Logo"
            />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i
              className={
                click
                  ? [navbar ? "fas fa-times" : "fas fa-times active"]
                  : [navbar ? "fas fa-bars" : "fas fa-bars active"]
              }
            />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {Array.from(data.navpath, (e, i) => {
              return e.sub.length === 0 ? (
                <li className="nav-item">
                  <Link
                    key={i + 1}
                    to={e.path}
                    className={
                      window.location.pathname === data.navpath[i].path
                        ? "selected"
                        : [navbar ? "nav-links" : "nav-links active"]
                    }
                  >
                    {t(`navpath.${i}.name`, { returnObjects: true })}
                  </Link>
                </li>
              ) : (
                <>
                  <li className="nav-item-dd" key={i}>
                    <div
                      className={
                        window.location.pathname === e.path
                          ? "selected"
                          : navbar
                            ? "nav-links-dd"
                            : "nav-links-dd active"
                      }
                      onClick={toggleMobileDropdown}
                    >
                      <Row>
                        {window.innerWidth <= 960 ? (
                          <div className="center">
                            {t(`navpath.${i}.name`, {
                              returnObjects: true,
                            })}{" "}
                            <FontAwesomeIcon icon={faCaretDown} />
                          </div>
                        ) : (
                          <>
                            <Col md={8.5}>
                              {t(`navpath.${i}.name`, { returnObjects: true })}
                            </Col>
                            <Col md={1}>
                              <FontAwesomeIcon icon={faCaretDown} />
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                    {e.sub && e.sub.length > 0 && (
                      <ul className={dropdownClass}>
                        {e.sub.map((sub, j) => (
                          <li key={j} className="sub-nav-item">
                            <Link
                              to={sub.path}
                              className={
                                window.location.pathname === sub.path
                                  ? "selected"
                                  : "nav-links-dd"
                              }
                            >
                              {t(`navpath.${i}.sub.${j}.name`, {
                                returnObjects: true,
                              })}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                </>
              );
            })}
            <select
              className="custom-select"
              onChange={(e) => handleChooseLan(e.target.value)}
              value={selectedLanguage}
            >
              {Array.from(languages, (lang, i) => {
                return <option value={lang[1]}>{lang[0]}</option>;
              })}
            </select>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
