import React from "react";
import "./Benefit.css";
import { useTranslation } from "react-i18next";
import data from "../../../data";
import { Col, Row } from "react-bootstrap";

function BenefitVVB() {
  const { t } = useTranslation();

  return (
    <>
      <div className="tkdn-container-definition">
        <div className="tkdn-section-header">
          <div className="border-title-vpti" />
          <h1 className="tkdn-section-title">{t("title_lvv_benefit")}</h1>
        </div>
        <div className="container-about-img">
          {Array.from(data.benefit_lvv, (benefit, i) => {
            return (
              <Row>
                <Col key={i}>
                  <img
                    className="benefit-img"
                    src={require(`../../../images/LVV/Benefit/${benefit}.png`)}
                    alt={benefit}
                  />
                  <h2 className="lvv-subtitle">
                    {t(`benefit_lvv.${i}.title`)}
                  </h2>
                  <p className="tkdn-text">{t(`benefit_lvv.${i}.desc`)}</p>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default BenefitVVB;
