import React from "react";
import { useTranslation } from "react-i18next";

function Commitment() {
  const { t } = useTranslation();

  return (
    <>
      <div className="tkdn-container-definition">
        <div className="tkdn-section-header">
          <div className="border-title-vpti" />
          <h1 className="tkdn-section-title">
            {t("commitment_impartiality_title")}
          </h1>
        </div>
        <div>
          <p style={{ padding: 0 }}>{t("commitment_impartiality_desc")}</p>
          <p>{t("commitment_impartiality_desc2")}</p>
        </div>
      </div>
    </>
  );
}
export default Commitment;
