import React from "react";
import "./ServiceContactUs.css";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function ContactUs() {
  const { t } = useTranslation();

  const whatsappContact = [
    {
      name: "Meidina Aysha Anindita",
      phone: "+62 852-8208-6769",
      link: "https://wa.me/6285282086769",
    },
    {
      name: "Novia Giselaputri Sukandar",
      phone: "+62 812-9511-1380",
      link: "https://wa.me/6281295111380",
    },
    {
      name: "Halija Syuchah",
      phone: "+61 851-2104-4449",
      link: "https://wa.me/6185121044449",
    },
    {
      name: "Thalia Hermawati",
      phone: "+62 895-2449-3330",
      link: "https://wa.me/6289524493330",
    },
    {
      name: "Ferdy Hartono",
      phone: "+62 813-9626-7820",
      link: "https://wa.me/6281396267820”",
    },
  ];

  return (
    <>
      <div className="tkdn-container-definition">
        <div className="tkdn-section-header">
          <div className="border-title-vpti" />
          <h1 className="tkdn-section-title">{t("contact_title")}</h1>
        </div>
        <div>
          <Row>
            <p className="contact-detail">{t("lvv_contact_us")}</p>
          </Row>
          <Row style={{ gap: "1rem" }}>
            <Col md={4}>
              <div>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "black", marginRight: "15px" }}
                />
                <a
                  href="tel:02122606207"
                  style={{ fontWeight: 400, color: "black", fontSize: "15px" }}
                >
                  +62 21-2260-6207
                </a>
              </div>
              <div className="wrapperWhatsApp">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  style={{ color: "black", marginRight: "15px" }}
                />
                <div className="containerWhatsapp">
                  {whatsappContact.map((cp) => {
                    return (
                      <a
                        href={cp.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="whatsapp"
                      >
                        <p>{cp.name}</p>
                        <p>{cp.phone}</p>
                      </a>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "black", marginRight: "15px" }}
                />
                <a
                  href="mailto:lvvgrk.nek@anindya.biz"
                  style={{ fontWeight: 400, color: "black", fontSize: "15px" }}
                >
                  lvvgrk.nek@anindya.biz
                </a>
              </div>
              <div style={{ margin: "10px 0 0 0" }}>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={{
                    color: "black",
                    marginRight: "15px",
                    marginLeft: "2px",
                  }}
                />
                <a
                  href="https://www.google.com/maps/search/?api=1&query=Springhill Office Tower, Lt.19, Pademangan Timur, Jakarta Utara"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: 400, color: "black", fontSize: "15px" }}
                >
                  Springhill Office Tower, Lt.19, Pademangan Timur, Jakarta
                  Utara
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default ContactUs;
