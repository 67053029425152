import React from "react";
import "./Service.css";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import data from "../../../data";

function Service() {
  const { t } = useTranslation();

  return (
    <>
      <div className="vvb-container-about">
        <div className="about-header">
          <div className="border-title-vpti" />
          <h1 className="vvb-title">{t("title_service_lvv")}</h1>
        </div>
        <div className="vvb-tab-container">
          <Row>
            <Tabs defaultActiveKey={1} id="controlled-tab-example">
              {Array.from(data.services_desc, (item, i) => {
                return (
                  <Tab eventKey={i + 1} title={t(`services_desc.${i}.title`)}>
                    <div>
                      <Row>
                        <Col md={3}>
                          <img
                            className="service-img"
                            src={require(
                              `../../../images/LVV/Services/${item.title}.png`
                            )}
                            alt={item.title}
                          />
                        </Col>
                        <Col md={9}>
                          <Row>
                            <p className="service-title">
                              {t(`services_desc.${i}.title`)}
                            </p>
                          </Row>
                          <Row>
                            <p className="service-desc">
                              {t(`services_desc.${i}.desc`)}
                            </p>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <p className="scope">
                                {t(`services_desc.${i}.scope.title`)}
                              </p>
                            </Col>
                            <Row className="list-scope">
                              {Array.from(item.scope.list, (data, j) => {
                                return (
                                  <Col>
                                    <Row>
                                      <p
                                        className="service-subservice-title"
                                        style={{ margin: "0 0 0 20px" }}
                                      >
                                        {t(
                                          `services_desc.${i}.scope.list.${j}.title`
                                        )}
                                      </p>
                                    </Row>
                                    <Row md={12}>
                                      <ul style={{ margin: "0 0 0 55px" }}>
                                        {data.list.map((obj, k) => {
                                          return (
                                            <li className="service-list">
                                              {t(
                                                `services_desc.${i}.scope.list.${j}.list.${k}`
                                              )}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </Row>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Service;
