import React from "react";
import "./AboutVVB.css";
import { useTranslation } from "react-i18next";
import { ButtonVPTI } from "../../Button";

function About() {
  const { t } = useTranslation();

  return (
    <>
      <div className="vvb-container-about">
        <div className="about-header">
          <div className="border-title-vpti" />
          <h1 className="vvb-title">{t("about_vvb")}</h1>
        </div>
        <div className="about-desc-container">
          <div className="tkdn-desc-flex">
            <p className="about-text">
              {" "}
              <em>{t("desc_italic")}</em>
            </p>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <ButtonVPTI
                className="btns"
                buttonStyle="btn--primary"
                buttonSize="btn--xsmall"
                pathn={t("lvv_link")}
              >
                {t("button_about")}
              </ButtonVPTI>
            </div>
          </div>
          <div className="tkdn-desc-flex">
            <p className="about-text">{t("desc")}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
