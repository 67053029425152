import React from "react";
import "./AppealFlow.css";
import { useTranslation } from "react-i18next";
import { Row, Tab, Tabs } from "react-bootstrap";
import data from "../../../data";
import { FlowImage } from "../../FlowImage";

function AppealFlow() {
  const { t } = useTranslation();
  return (
    <>
      <div className="tkdn-container-definition">
        <div className="tkdn-section-header">
          <div className="border-title-vpti" />
          <h1 className="tkdn-section-title">{t("appeal_flow_title")}</h1>
        </div>
        <div>
          <Row>
            <Tabs defaultActiveKey={1} id="controlled-tab-example">
              {Array.from(data.handling, (item, i) => {
                return (
                  <Tab eventKey={i + 1} title={t(`handling.${i}.title`)}>
                    <div>
                      <Row>
                        <p>{t(`handling.${i}.desc`)}</p>
                      </Row>
                      <Row>
                        <FlowImage
                          mobile_file_src={`/images/LVV/${item.title}-mobile`}
                          desktop_file_src={`/images/LVV/${item.title}`}
                        />
                      </Row>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </Row>
        </div>
      </div>
    </>
  );
}
export default AppealFlow;
