import React, { useState, useEffect } from "react";
import "./pages/vvb/FlowService.css";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const FlowImage = ({ mobile_file_src, desktop_file_src }) => {
  const { t } = useTranslation();

  const [mobile, setMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    mobileVer();
  }, []);

  const mobileVer = () => {
    if (window.innerWidth <= 960) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  window.addEventListener("resize", mobileVer);

  const containerClass = classNames({
    "flow-img-container-cut": mobile && !isExpanded,
    "flow-img-container": mobile && isExpanded,
  });

  const setVisibilityImage = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <div className={containerClass}>
        <img
          className="flow-img-full"
          src={
            mobile
              ? `${mobile_file_src}-${t("image_language")}.svg`
              : `${desktop_file_src}-${t("image_language")}.svg`
          }
          alt={"Service Process"}
        />
      </div>
      {mobile ? (
        isExpanded ? (
          <button className="btn btn--primary" onClick={setVisibilityImage}>
            {t("hide_flow_button")}
          </button>
        ) : (
          <>
            <div className="gradient"></div>
            <button className="btn btn--primary" onClick={setVisibilityImage}>
              {t("show_flow_button")}
            </button>
          </>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
