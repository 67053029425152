import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom"
import HeroSection from './HeroSectionVVB'
// import Flow from './Flow'
import Navigation from '../../Navbar';
import About from './AboutVVB';
import BenefitVVB from './Benefit';
import Service from './Service';
import FlowService from './FlowService';
import Commitment from './Commitment';
import AppealFlow from './AppealFlow';
import ContactUs from './ServiceContactUs';
// import Import from './Import';
// import Desc from './Desc';

function VVB() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [pathname])

    return (
        <>  
            <Navigation />
            <HeroSection/>
            <About />
            <div style={{backgroundColor: "#F2F2F2"}}>
                <BenefitVVB/>
            </div>
            <Service />
            <div style={{backgroundColor: "#F2F2F2"}}>
                <FlowService/>
            </div>
            <Commitment/>
            <div style={{backgroundColor: "#F2F2F2"}}>
                <AppealFlow/>
            </div>
            <ContactUs />
        </>
    )
}

export default VVB
